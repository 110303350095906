<template>
    <!-- 전체 콘텐츠 영역 -->
    <div class="main-contents">
        <div class="mem_container">
            <div class="mem_contents_header myp">
                <div class="title-txt"><nbsp n="2"/>{{$store.state.userInfo.mberNm}}님 반갑습니다!<br/>
                    <p class="myp" v-if="notiState">새로운 요청이 있습니다.</p>
                </div>
            </div>
            <div class="myp-banner" v-if="notiState == '01'">
                <img src="/images/tec/myp/certifi_banner.jpg" alt="마이페이지배너"/>
                <p>
                    미등록된 증명서를 업로드 해 주시면, 추가 적립 포인트를 드립니다!<br/>
                    <span>적립포인트는 향후 수행하실 프로젝트 완료후에 지급됩니다!</span>
                </p>
                <div class="shortcut" @click="$router.push('PRF101M01')">바로가기 &#9654;</div>
            </div>
            <div class="mem_info_contents">
                <tec-info ref="info" v-model="info" :tecMberSeq="$store.state.userInfo.mberSeq"/>
                <tec-resume-view ref="tecResumeView" v-model="resume"  :tecMberSeq="$store.state.userInfo.mberSeq"/>
            </div>
        </div>
    </div>
</template>

<script>
import tecInfo from "@/components/highpro/TecInfo.vue";
import tecResumeView from "@/components/highpro/TecResumeView.vue";

export default {

        components: { tecInfo, tecResumeView },
        data() {
            return {
                notiState : '',
                state : {},
                info: { },
                resume: { },
            };
        }, 
        

        // beforeMount(){
        //     this.$.httpPost('/api/mem/prf/getResumeState')
        //             .then((res) => {
        //                 if(res.data){
        //                     this.state = res.data;
        //                     if(this.state.resmYn == 'N'){
        //                         this.notiState = '01';
        //                     }
        //                 }
        //             }) 
        // },

        mounted() {
            this.$.httpPost('/api/mem/prf/getResumeState')
                    .then((res) => {
                        if(res.data){
                            this.state = res.data;
                            if(this.state.resmYn == 'N'){
                                this.notiState = '01';
                            }
                        }
                    }) 
        },

        // beforeDestroy() {
        //     window.removeEventListener("beforeunload", event);
        // },
        
        methods: {
        },
    };
</script>